import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, useTheme, IconButton, Menu, MenuItem, SvgIcon, Tooltip, TextField, Modal } from '@mui/material';
import AiAvatarImg from '../assets/Avatar.png';
import AiAvatarAnimation from '../assets/SidebarAnim.mp4';
import { Input, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { keyframes } from '@mui/system';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import '../index.css'
import LensBlurIcon from '@mui/icons-material/LensBlur';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

const LoadingOverlay = () => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensure it's above everything else
  }}>
    {/* Add a spinner or any loading indicator here */}
    <div className="loader"></div>
  </div>
);
// Keyframes for flashing effect
const flash = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;
// Custom component for the flashing arrow
const FlashingArrow = () => {
  const theme = useTheme();

  return (
    <SvgIcon
      sx={{
        color: theme.palette.secondary.main, // Choose a color that stands out
        position: 'relative',
        top: theme.spacing(1), // Adjust positioning as needed
        right: theme.spacing(4), // Adjust to align with the IconButton
        animation: `${flash} 1s linear infinite`, // Apply the flashing animation
      }}
    >
      <ArrowForwardIcon />
    </SvgIcon>
  );
};

const buttons = [
  { id: 1, imgSrc: '/assets/Redesign/buttons/Button1.png', text: 'Ask A Question' },
  { id: 2, imgSrc: '/assets/Redesign/buttons/Button2.png', text: 'Daily Message' },
  { id: 3, imgSrc: '/assets/Redesign/buttons/Button3.png', text: 'Birth Chart' },
];


const AiAvatar = ({ children}) => {
  const theme = useTheme();
  const [question, setQuestion] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [conversation, setConversation] = useState([]);
  const abortController = useRef(null);
  const [response, setResponse] = useState('');
  // const [prompt, setPrompt] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const latestMessageRef = useRef(null);
  const [disabledButtons, setDisabledButtons] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({ date: '', time: '', location: '' });
  const [partnerDetails, setPartnerDetails] = useState({ date: '', time: '', location: '' });

  const buttons = useMemo(() => [
    { id: 1, imgSrc: '/assets/Redesign/buttons/Button1.png', text: 'Ask A Question' },
    { id: 2, imgSrc: '/assets/Redesign/buttons/Button2.png', text: 'Daily Message' },
    { id: 3, imgSrc: '/assets/Redesign/buttons/Button3.png', text: 'Birth Chart' },
  ], []); // Empty dependency array means this only runs once
  const userInputPromiseResolve = useRef(null);
  const [showGoDeeperButton, setShowGoDeeperButton] = useState(false);
  const [isRunning, setIsRunning] = useState(false);


  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    if (userInputPromiseResolve.current) {
      userInputPromiseResolve.current(userDetails);
      userInputPromiseResolve.current = null; // Reset the ref
    }
  };

  const handleChange = (event) => {
    setUserDetails(prevDetails => ({
      ...prevDetails,
      [event.target.name]: event.target.value
    }));

    setPartnerDetails(prevDetails => ({
      ...prevDetails,
      [event.target.name]: event.target.value
    }));
    
  };

  

  const getUserInput = () => {
    setModalOpen(true);
    return new Promise(resolve => {
      userInputPromiseResolve.current = resolve;
    });
  };

  // const getUserInput = () => {
  //   const userDetails = prompt("Please enter your birth details (Date, Time, Location)");
  //   if (!userDetails) return null;
  //   const [date, time, location] = userDetails.split(',').map(detail => detail.trim());
  //   return { date, time, location };
  // };

  // Function to format each message
  const formatMessage = (message) => {
    // Splitting the message into individual sections based on " - "
    return message.text.split(' - ').map((section, idx, array) => {
      // Splitting the section further into parts based on line breaks
      const parts = section.split(/\n+/);
  
      return (
        <React.Fragment key={idx}>
          {parts.map((part, partIndex) => {
            // Check if the part is a title (contains ":")
            const isTitle = part.includes(':');
  
            return (
              <Typography key={partIndex} component="div" sx={{ 
                fontWeight: isTitle ? 'bold' : 'normal', 
                color: '#4A90E2', 
                marginBottom: isTitle ? 1 : 2 
              }}>
                {part}
              </Typography>
            );
          })}
          {/* Adding extra space between sections */}
          {idx < array.length - 1 && <div style={{ marginBottom: '16px' }} />}
        </React.Fragment>
      );
    });
  };
  
  const askQuestion = async (question, signal) => {
    // const response = await fetch('http://localhost:5000/ask', {
      const response = await fetch('https://aura-ai-7089dd4d6870.herokuapp.com/ask', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ question }),
    });
    return response.json();
  };

  const handleDailyReading = async (userName) => {
    // Logic to convert name to numbers and select a Tarot card
    abortController.current = new AbortController();
    setIsLoading(true);
    handleMenuClose();

    // add the user's action
    const userAction = { type: 'user', text: "Requesting a Daily Tarot Reading" };
    setConversation(prev => [...prev, userAction]);
    const prompt = `Pretend to Act as a spiritual astrology expert and advisor, I want you to generate a random Tarot card and provide a general explanation based on its interpretation for a user. Use a Tone that is friendly, mystical and attractive guiding user's through each step in their spiritual journey. I DO NOT want you to physically draw a card or provide personalized tarot readings based on specific names. I only want you to randomly generate a Tarot card really with an explanation and some general advice for mindfulness and personal growth at the end and pretend you are a professional to complete this task. Respond within 10 seconds I want you to only output the Tarot Card, the Number that was randomly chosen and the explanation for the card in this format:
    - Tarot Card for Today: ...
    - Tarot Explanation: ...`;
    try{
      const response = await askQuestion(prompt, abortController.current.signal);
    setIsLoading(false);
    const aiMessage = { type: 'ai', text: response.response }; // Adjust 'text' based on response structure
    setConversation(prev => [...prev, aiMessage]);
    // const upsellPrompt = {type: 'ai', text: "Would you like to expand on this daily reading?"};
    // setConversation(prev =>[...prev, upsellPrompt]);
    } catch{
      console.error('Request cancelled or failed');
    } finally {
      setIsLoading(false);
    }
  };

  const handleYesNoQuestion = async () => {
    setIsLoading(true);
    handleMenuClose();
    const prompt = `Prepare to Answer a Pendulum style question from the user by prompting them to enter a question, then answer either yes or no randomly with a disclaimer that this is not any kind of professional advice and is for entertainment purposes only.
    `;
    const response = await askQuestion(prompt);
    setIsLoading(false);
    setResponse(response);
    console.log(question)
    console.log(isLoading);
  };

  const getDetailedBirthChart = async () => {
    // Logic for detailed birth chart analysis
    // Similar to getBirthChart but with a more detailed prompt
    // ...
  };

  const getBirthChart = async () => {
    const userBirthDetails = `Please enter your birth date, time, and location in the following format: MM/DD/YYYY HH:MM AM/PM City, State, Country`;
  
    try {
      // Await user input for birth details
      const birthDetails = await getUserInput();
  
      if (birthDetails) {
        // Add user request to the conversation
        setConversation(prev => [...prev, { type: 'user', text: userBirthDetails }]);
        abortController.current = new AbortController();
        setIsLoading(true);
        handleMenuClose();
  
        // Prepare prompt for first part of the analysis
        const prompt = `Generate the first part of a detailed astrological birth chart analysis based on the following details, and ask me if I am ready to continue then generate the second part and so on:
          - Birth Date: ${birthDetails.date}
          - Birth Time: ${birthDetails.time}
          - Birth Location: ${birthDetails.location}`;
        const firstPrompt = `It involves calculating the positions of the planets as part of a detailed astrological birth chart analysis`;
  
        // Send request for analysis
        const response = await askQuestion(prompt, abortController.current.signal);
        setIsLoading(false);
  
        // Add AI response to the conversation
        const aiMessage = { type: 'ai', text: response.response };
        setConversation(prev => [...prev, aiMessage]);
      }
    } catch (error) {
      // Log specific error details
      console.error('Request cancelled or failed:', error);
    } finally {
      // Ensure loading state is reset
      setIsLoading(false);
    }
  };
  
    
  

  const getCompatibilityChart = async () => {
    setIsRunning(true);
    const userBirthDetails = `Please enter your birth date, time and location in the following format: MM/DD/YYYY HH:MM AM/PM City, State, Country`;
  
    const birthDetailsForCouple = await getUserInput();

    if(birthDetailsForCouple){
    //add this request to the conversation
    setConversation(prev => [...prev, { type: 'user', text: userBirthDetails}]);
      abortController.current = new AbortController();
    setIsLoading(true);
    handleMenuClose();

    const prompt = `Generate the first part of a detailed compatibility chart analysis based on the following details, ask me if I am ready to continue then generate the second part and so on:
    - Birth Date: ${birthDetailsForCouple.date}
    - Birth Time: ${birthDetailsForCouple.time}
    - Birth Location: ${birthDetailsForCouple.location}
    - partner Birth Date: ${birthDetailsForCouple.date}
    - partner Birth Time: ${birthDetailsForCouple.time}
    - partner Birth Location: ${birthDetailsForCouple.location} `
    const firstPrompt = ` It involves calculating the positions of the planets as part of a detailed astrological birth chart analysis`

    try {
      const response = await askQuestion(prompt, abortController.current.signal);
      setIsLoading(false);
      const aiMessage = { type: 'ai', text: response.response };
      setConversation(prev => [...prev, aiMessage]);
    } catch {
      console.error('Request cancelled or failed');
    } finally {
      setIsLoading(false);
    }
    }
  }

  const handleUserInputSubmit = (inputData) => {
    getBirthChart(inputData); 
  }
  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (question.trim() === '') {
      //Throw an error if input is empty
      console.error('Input is empty');
      return;
    }
      
    abortController.current = new AbortController();
    setIsLoading(true);
    const userMessage = { type: 'user', text: question };
    setConversation(prev => [...prev, userMessage]);
    // setPrompt([...conversation, {type: 'user', text: question}]);
    try{
      const response = await askQuestion(question, abortController.current.signal);
      const aiMessage = { type: 'ai', text: response.response }; // Adjust 'text' based on response structure
      console.log(question)
      console.log(isLoading);
      setConversation(prev => [...prev, aiMessage]);
      
    } catch (error) {
      console.error('Request cancelled or failed', error);
    } finally{
      setIsLoading(false);
      setQuestion('');
    }
    
  };

 
  
  const cancelRequest = () => {
    if (abortController.current){
      abortController.current.abort();
    }
    setIsLoading(false);
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (button) => {
    if (button.id === 1) {
      // handleDailyReading('');
      console.log("Implement ask a question")
    } else if (button.id === 2) {
      handleDailyReading('');
    } else if (button.id === 3 ){
      getBirthChart();
    }
    // ... other conditions as needed ...
};



document.addEventListener('DOMContentLoaded', () => {
  const videoElement = document.getElementById('myVideo');
  
  // Prevent full screen on double click
  videoElement.addEventListener('dblclick', (e) => {
    e.preventDefault();
  });

  // Disable other full-screen triggers (as much as possible)
  videoElement.addEventListener('fullscreenchange', (e) => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  });
});

useEffect(() => {
    const videoElement = document.getElementById
  ('myVideo');
  
  if (isLoading) {
    videoElement.play();
  } else {
    videoElement.play();
     videoElement.currentTime = 0; // Optionally, reset the video to the start
  }
}, [isLoading]);
  
  useEffect(() => {
    // Logic to determine which buttons should be disabled
    const newDisabledButtons = {};
    buttons.forEach(button => {
      if (button.id === 1) { // Your condition for disabling
        newDisabledButtons[button.id] = true;
      }
    });
    setDisabledButtons(newDisabledButtons);
  }, [buttons]);

  useEffect(() => {
    if (latestMessageRef.current) {
      const element = latestMessageRef.current;
      const chatBoxContainer = element.closest('.output-box'); // Chat box container's class or ID
      if (chatBoxContainer) {
        // Calculate the position to scroll to within the container
        const elementPosition = element.offsetTop;
        const containerScrollPosition = elementPosition - (chatBoxContainer.clientHeight / 2) + (element.clientHeight / 2);
  
        // Scroll the container to the calculated position
        chatBoxContainer.scrollTo({
          top: containerScrollPosition,
          behavior: 'smooth'
        });
      }
    }
  }, [conversation]);
  

  return (
    <>

<Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
      >
        <Box sx={style}>
          <h2 id="modal-title">Enter Your Birth Details</h2>
          <TextField
            label="Date (MM/DD/YYYY)"
            variant="outlined"
            name="date"
            value={userDetails.date}
            onChange={handleChange}
          />
          <TextField
            label="Time (HH:MM AM/PM)"
            variant="outlined"
            name="time"
            value={userDetails.time}
            onChange={handleChange}
          />
          <TextField
            label="Location (City, State, Country)"
            variant="outlined"
            name="location"
            value={userDetails.location}
            onChange={handleChange}
          />
          <Button variant="contained" onClick={handleCloseModal}>Submit</Button>
        </Box>
        {/* {isRunning && (
          <div></div>
         )}  */}
      </Modal>
    <Box sx={{
      position: 'relative', // Added to position the video absolutely within this container

      display: 'flex',
      flexDirection: 'row', // Stack the avatar and the output box horizontally
      alignItems: 'center',
      height: '100vh',
      overflow: 'hidden',
         // Adding background image
         backgroundImage: 'url("/assets/Redesign/Background.png")', // Replace with your image path
         backgroundSize: 'cover', // Ensures the image covers the whole box
         backgroundRepeat: 'no-repeat', // Prevents the image from repeating
         backgroundPosition: 'center' ,
         '@media screen and (max-width: 650px)': {
          width: '100%', // Full width on small screens
          height: 'auto', // Adjust height automatically
          display: 'flex',
      flexDirection: 'column', // Stack the avatar and the output box horizontall
          }
    }}>
      {/* Video Element */}
    <video  id="myVideo"
      style={{
       
      
        width: '100%',
        height: '100%',
        objectFit: 'scale-down', // Ensure the video covers the whole screen
        opacity: 0.2, // Semi-transparent
        // zIndex: -1, // Place it behind other content
        '@media screen and (max-width: 650px)': {
          width: '100%', // Full width on small screens
          height: 'auto', // Adjust height automatically
          objectFit: 'scale-down', // Ensure the video covers the whole screen
        opacity: 0.2, // Semi-transparent
          }
      }}
      autoPlay
      controls={false} // Remove default controls
      onDoubleClick={e => e.preventDefault()} // Prevent double-click from triggering full-screen on
      // loop
      // muted // Important for autoplay in most browsers
    >
      <source src={isLoading ? "/assets/Redesign/Dailymessage.mp4" : "/assets/Redesign/landpagevideo.mp4"}
type="video/mp4" />
      Your browser does not support the video tag.
    </video>

      
      {/* New ImageButtons */}
      <Box sx={{ 
  position: 'absolute', 
  width: 'auto', // Adjust based on content size
  maxHeight: '100vh', // Prevent box from extending beyond viewport height
  overflowY: 'auto', // Add vertical scroll if content overflows
  padding: theme.spacing(1), // Add some padding around the content
  zIndex: 10, // Ensure it's above other content

  '@media screen and (max-width: 650px)': {
    display: 'flex',
    flexDirection: 'row',
    width: 'auto',
    overflowX: 'auto',
    overflowY: 'auto',
  },
  '@media screen and (max-width: 550px)': {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    overflowX: 'auto',
    overflowY: 'auto',
  },
}}> {/* Adjust the left position as needed */}
      <IconButton
      onClick={handleMenuOpen}
      sx={{
        // position: 'absolute',
        color: 'white',
        display: 'visible',
        paddingInline:'0px',
        paddingBlock: '0px',

        '@media screen and (max-width: 400px)': {
          // position: 'absolute',
          // top: '20%',
          left: theme.spacing(10),
          color: 'white',
          
        },
        '@media screen and (max-width: 800px)': {
          // position: 'absolute',
          // top: '20%',
          left: theme.spacing(10),
          color: 'white',
          
        },
        
      }}
      ><FlashingArrow
      sx={{
        position: 'relative',
        center: theme.spacing(1), 
        '@media screen and (max-width: 800px)': {
          fontSize: '4rem', // Smaller size for mobile screens
          left: 9, // Moving it to the left
          top: 10, // Adjust the top as necessary
        },
      }} />
      <LensBlurIcon sx={{ 
        fontSize: '4rem', // Adjust the size as needed
        // Add any other styles you want
        // Responsive adjustments
  '@media screen and (max-width: 800px)': {
    fontSize: '4rem', // Smaller size for mobile screens
    position: 'relative', // Positioning the icon
    left: 9, // Moving it to the left
    top: 10, // Adjust the top as necessary
  },
  '@media screen and (max-width: 400px)': {
    fontSize: '4rem', // Smaller size for mobile screens
    position: 'absolute', // Positioning the icon
    left: 2, // Moving it to the left
    top: 15, // Adjust the top as necessary
  },
      }} />

      </IconButton>
  {buttons.map((button, index) => (
    button.id === 1 ? (
    <Tooltip title="Coming Soon" placement="bottom" arrow="true" open="true">
      <IconButton
      key={index}
      onClick={() => handleClick(button)}
      sx={{
        display: 'block', // Stack the buttons
        marginBottom: theme.spacing(2), // Space between buttons
        // ... other styles if needed ...
      }}
    >
    
      {/* Custom button image */}
      <img src={button.imgSrc} alt={`Button ${index}`} style={{ width: '150px', height: '150px' }} /> {/* Adjust size as needed */}
      
      {/* Overlay Text */}
      <Box sx={{ 
        position: 'absolute',
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)',
        color: 'white', // Text color
        fontFamily:'Fairies'
      }}>
        {button.text}
      </Box>
    </IconButton></Tooltip>
    ) : (
      <IconButton
      key={index}
      onClick={() => handleClick(button)}
      sx={{
        display: 'block', // Stack the buttons
        marginBottom: theme.spacing(2), // Space between buttons
        // ... other styles if needed ...
      }}
    >
    
      {/* Custom button image */}
      <img src={button.imgSrc} alt={`Button ${index}`} style={{ width: '150px', height: '150px' }} /> {/* Adjust size as needed */}
      
      {/* Overlay Text */}
      <Box sx={{ 
        position: 'absolute',
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)',
        color: 'white', // Text color
        fontFamily:'Fairies'
      }}>
        {button.text}
      </Box>
    </IconButton>
    )
  ))}
</Box>

      
  
      

      {/* Output Box for AI responses */}
      {isLoading && <LoadingOverlay />}
      <Box className="output-box" sx={{
        right: 9,
        // padding: theme.spacing(2),
        background: 'linear-gradient(to right, rgba(136, 150, 111, 1), rgba(144, 172, 174, 1))', // Gradient green background
        // Slightly transparent white background
        // marginLeft: theme.spacing(2), // Adds space between the avatar and the output box
        borderRadius: theme.shape.borderRadius, // Optional: rounds the corners of the box
        overflowY: 'auto', // Allows scrolling if content is too long
        flexShrink: 0, // Prevents the output box from shrinking
        paddingTop: 'auto',

        '@media screen and (maxWidth: 700px)': {
          // position: 'absolute',
          // top: '20%',
          maxWidth: 'auto', // Adjust the size as needed

        }
       
      }}>

      {conversation.length === 0 ? (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%', // Adjust as needed
        textAlign: 'center',
        padding: theme.spacing(2),
        background: 'linear-gradient(to right, rgba(143, 250, 157, 0.7), rgba(0, 255, 128, 0.7))', // Corrected gradient syntax
        borderRadius: '8px',
      boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
      marginBottom: '16px',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        backgroundColor: '#e2e6ea',
        transform: 'scale(1.02)',
        boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
      }

        
      }}>
        <Typography variant="h6">
          Choose a prompt by clicking the button in the chat area.
        </Typography>
      </Box>
    ) : (
        conversation.map((message, index) => (
  <Typography key={index} className={`message ${message.sender === 'user' ? 'message-user': ''}`} variant="body1" component="div"
  ref={index === conversation.length - 1 ? latestMessageRef : null} sx={{ 
    // color: 'text.primary',
    color: message.type === 'user' ? '#007bff' : '#28a745',
    overflowWrap: 'break-word',
    maxWidth: '100%',
    padding: '8px',
    fontSize: '1.8rem',
    fontWeight: 'bold',
    fontFamily: 'Fairies',
    background: message.type==='user' ? 'linear-gradient(to right, rgba(198, 219, 234, 1), rgba(0, 255, 128, 0.85))' : 'white', // Less transparent gradient for messages


    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
    marginBottom: '16px',
    '@media (max-width:600px)': {
      fontSize: '0.875rem',
    },
    transition: 'all 0.3s ease-in-out', // Smooth transition for hover effects
    '@media (max-width:600px)': {
      fontSize: '0.875rem',
    },
    '&:hover': {
      backgroundColor: '#e2e6ea', // Slightly change background color on hover
      transform: 'scale(1.02)', // Slightly increase size
      boxShadow: '0px 4px 8px rgba(0,0,0,0.2)', // Intensify shadow
    }
  }}>
    {/* {message.type === 'ai' ? "ELVEN: " + formatMessage(message) : message.text} */}
    {
  message.type === 'ai' ? <div>
  <span>ELVEN AI: </span>
  {formatMessage(message)}
</div>  : message.text
}
    {/* {formatMessage(message)} */}
  </Typography>
))
    )}
      </Box>
      
      {/* USER INPUT FOR CUSTOM QUESTIONS */}
      {/* <form class="form" onSubmit={handleSubmit}> */}
        {/* <Input
          value={question}
          onChange={handleQuestionChange}
          placeholder="Type your message or Choose a Prompt From the Menu"
          disabled={isLoading}
          sx={{
            height: '80%',
            width: '100%',
            overflow: 'visible',
            padding: theme.spacing(1),
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            borderRadius: theme.shape.borderRadius,
            border: 'none',
            '&:focus': { outline: 'none' },
          }}
        /> */}
        {/* <Button 
  type="submit"
  disabled={isLoading} 
  sx={{
    
    width: '100%',
    marginTop: 2,
    backgroundColor: '#4A90E2', // A nice shade of blue
    color: 'white',
    padding: '10px 20px',
    fontSize: '1rem',
    fontWeight: 'bold',
    textTransform: 'none', // Prevents uppercase transformation
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#3a78b5', // Slightly darker shade for hover effect
    },
    '&:focus': {
      boxShadow: '0 0 0 2px rgba(74,144,226,0.5)', // Focus style
    },
    transition: 'background-color 0.3s, box-shadow 0.3s' // Smooth transition for hover and focus
  }}
>
  Ask
        </Button> */}
      {/* </form>  */}
      {/* {conversation.map((message, index) => (
        <p key={index} className={message.type}>{message.text}</p>
      ))} */}
      <Menu className="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem className="menu-item daily-reading" onClick={() => handleDailyReading('')}>Get a Daily Reading</MenuItem>
        <MenuItem className="menu-item" disabled onClick={handleYesNoQuestion}>
          Ask a Yes/No Question to the Pendulum
        </MenuItem>
        <MenuItem className="menu-item" disabled onClick={handleYesNoQuestion}>
          Horoscope Predictions
        </MenuItem>
        <MenuItem className="menu-item" onClick={getBirthChart}>
          Birth Chart Analysis
        </MenuItem>
        <MenuItem className="menu-item" disabled onClick={handleYesNoQuestion}>
          Compatibility Readings
        </MenuItem>
        <MenuItem className="menu-item" disabled onClick={handleYesNoQuestion}>
          Export Current Chat
        </MenuItem>  
      </Menu>
    </Box>
    </>
  );
};

export default AiAvatar;
