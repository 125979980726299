import './App.css';
import React, {useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import { Box, Button, AppBar, Toolbar, Typography } from '@mui/material';
import Sidebar from './components/Sidebar';
import HeaderBG from './assets/HeaderBG.png'
import ChatArea from './components/ChatArea';
import Avatar from './assets/Avatar.png';
import AiAvatar from './components/AIAvatar';
import button1 from './assets/Button1-removebg-preview.png';
import SidebarWithVideo from './components/SidebarWithVideo';
import ChatAreaWithVideo from './components/ChatAreaWithVideo';
import ChatComponent from './components/ChatComponent';
import ChatbotSelectionPage from './pages/ChatbotSelectionPage';
import OnboardingModal from './components/OnboardingModal';
import HeroSection from './landing/Hero';
import LandingPage from './landing/LandingPage';
import PrivacyPolicy from './legal/PrivacyPolicy';
import TermsOfService from './legal/TermsOfService';
import RegisterForm from './login/RegisterForm';
import LoginForm from './login/LoginForm';
import { AuthProvider } from './components/Context';
// const AiAvatar = () => (
//   <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
//     <img src={Avatar} alt="AI Avatar" style={{ maxWidth: '100%', maxHeight: '100%', float: 'left' }} />
//   </Box>
// );

const ChatButton = () => {
  const buttonImage = '/path/to/button-image.png'; // Replace with the path to your button image

  return (
    <div
      style={{
        backgroundImage: `url(${button1})`,
        backgroundSize: 'cover',
        width: '60px', // Set the width of your button
        height: '50px', // Set the height of your button
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '8px', // Material-UI's theme.spacing(2) equivalent
        color: 'white', // Text color
        // Add any additional styling you need here
        cursor: 'pointer',
      '&:hover': {
        // Example hover effect
        opacity: 0.8,
        marginBottom: '10px',
      },
      }}
      onClick={() => {
        // Add your click event logic here
      }}
    >
      
    </div>
  );
};

// const SideBar = () => (
//   <Box sx={{ backgroundColor: 'lightgreen', width: '15%', height: '100vh', position: 'fixed', left: 0, top: 0 }}>
//     {/* Placeholder for Sidebar */}
//     <Typography>Sidebar Content</Typography>
//   </Box>
// );

// const ChatArea = () => (
//   <Box sx={{ backgroundColor: 'lightcoral', height: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//     {/* Placeholder for Chat Area */}
//     <Typography>Chat Area Here</Typography>
//   </Box>
// );

function App() {
  return (
  <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/pricing" element={<RegisterForm />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/select-chatbot" element={<ChatbotSelectionPage />} />
        <Route path="/chat/ElvenAI" element={<Home />} />
        <Route path="/chat/PendulumProphet" element={<Home />} />
        <Route path="/chat/CelestialScribe" element={<Home />} />
      </Routes>
    </Router>
    </AuthProvider>
  );
}
export default App;
