import React, { useState } from 'react';
import { Box, AppBar, Toolbar, Typography } from '@mui/material';
import SidebarWithVideo from './components/SidebarWithVideo';
import ChatArea from './components/ChatArea';
import AiAvatar from './components/AIAvatar';
import HeaderBG from './assets/HeaderBG.png';
import OnboardingModal from './components/OnboardingModal';
import NavigationBar from './landing/NagivationBar';

const Home = () => {
  const [response, setResponse] = useState('');

  return (
    <><NavigationBar /><Box sx={{ backgroundColor: 'black', flexGrow: 1 }}>
      <AppBar position="static">
        {/* <Toolbar sx={{ backgroundImage: `url(${HeaderBG})` }}>
          <Toolbar sx={{ backgroundImage: `url(${HeaderBG})` }} />

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          </Typography>
        </Toolbar> */}
      </AppBar>
      {/* <SidebarWithVideo /> */}
      <Box>
        <AiAvatar response={response} />
        {/* <ChatArea setResponse={setResponse} /> */}

      </Box>
      <OnboardingModal />
    </Box></>
    
  );
};

export default Home;
