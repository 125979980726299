import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import useState from 'react';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import StripeLogo from '../assets/partners/Stripe.png'

const cardStyle = {
    height: 'auto',
    minWidth: 275,
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    overflow: 'visible',
    '&:hover': {
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)', // Enhance shadow on hover
    },
    borderRadius: 2, // Rounded corners
  };

  
  const buttonStyle = {
    width: 150,
    height: 150,
    backgroundSize: 'cover',
    '&:hover': {
      transform: 'scale(1.05)', // Slightly scale up on hover
      transition: 'transform 0.3s ease-in-out',
    },
  };

const PricingTierCard = ({ credits, onSelect, button }) => (
    <>
    <Card sx={{ cardStyle }}>
      <CardContent>
        <Typography sx={{
             fontFamily:'Fairies',
        }} variant="h5" component="div">
          {credits} Credits
        </Typography>
        <Typography variant="body2"></Typography>
    
      {/* ... additional details ... */}
      </CardContent>
      <CardActions>
        <Button sx={{
            // backgroundImage: `url(${button.imgSrc})`,
            backgroundSize: 'cover',
            backgroundColor:'purple',
            width: 150,
            height: 'auto',
            color: 'white',
            fontFamily:'Fairies',
            fontWeight:'bold',
            alignSelf: 'center',
            '&:hover': {
                transform: 'scale(1.05)', // Slightly scale up on hover
                transition: 'transform 0.3s ease-in-out',
                backgroundColor: 'black',
            }

        }

        } size="small" onClick={() => onSelect(credits)}>Select</Button>
      </CardActions>
    </Card>
     
     </>
  );
  

// Style for the modal (customize as needed)
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: 'linear-gradient(to right, rgba(143, 250, 157, 0.7), rgba(0, 255, 128, 0.7))', // Corrected gradient syntax
  boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease-in-out',
      '&:hover': {
        backgroundColor: '#e2e6ea',
        // transform: 'scale(0.12)',
        boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
      },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'space-around',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 2,
  // Add your futuristic/spiritual theme and gradient here
};



const PricingModal = ({ isPricingOpen, setPricingOpen, buttons }) =>

 (
    
  <Modal open={isPricingOpen} onClose={() => setPricingOpen(false)}>
    <Box sx={modalStyle} onClose={() => setPricingOpen(false)}>
    <Typography sx={{
        fontWeight: 'bold',
    }} variant="h6" gutterBottom>
        Pick Your Tier
      </Typography>
      {/* Pricing Tier Cards */}
      <PricingTierCard credits="100" onSelect={(credits) => console.log(`Selected ${credits} credits`)} button={buttons[0]} />
      <PricingTierCard credits="500" onSelect={(credits) => console.log(`Selected ${credits} credits`)} button={buttons[1]} />
      <PricingTierCard credits="1000" onSelect={(credits) => console.log(`Selected ${credits} credits`)} button={buttons[2]} />
    
      <Typography variant="body2">Safe and secure payments with Stripe</Typography>
     <img src={StripeLogo} alt="Stripe" style={{ height: '30px', marginTop: '10px' }} />
    </Box>
  </Modal>
);

export default PricingModal;
