import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Logo from '../assets/ui.png'; // Import your logo
import {useNavigate} from 'react-router-dom';
import elvenLogo from '../assets/ui.png';
import PricingModal from '../components/PricingModal';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import StripeLogo from '../assets/partners/Stripe.png'


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: 'linear-gradient(to right, rgba(143, 250, 157, 0.7), rgba(0, 255, 128, 0.7))', // Corrected gradient syntax
  boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease-in-out',
      '&:hover': {
        backgroundColor: '#e2e6ea',
        // transform: 'scale(0.12)',
        boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
      },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection:'row',
  justifyContent: 'space-around',
  // Add your futuristic/spiritual theme and gradient here
};

const cardStyle = {
  minWidth: 275,
  textAlign: 'center',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)', // Enhance shadow on hover
  },
  borderRadius: 2, // Rounded corners
};

const buttonStyle = {
  width: 150,
  height: 150,
  backgroundSize: 'cover',
  '&:hover': {
    transform: 'scale(1.05)', // Slightly scale up on hover
    transition: 'transform 0.3s ease-in-out',
  },
};


const LoginModal = ({ isOpen, onClose }) => (
  <Modal open={isOpen} onClose={onClose}>
    <Box sx={modalStyle}>
      <Typography sx={{
        fontWeight: 'bold',
      }} variant="h6" gutterBottom>Login</Typography>
      <TextField label="Email" variant="outlined" fullWidth margin="normal" />
      <TextField label="Password" variant="outlined" fullWidth margin="normal" type="password" />
      <Button sx={{
          backgroundColor: 'purple',
          fontWeight:'bold',
        }} variant="contained" color="primary" fullWidth onClick={onClose}>Log In</Button>
    </Box>
  </Modal>
);

const SignUpModal = ({ isOpen, onClose }) => (
  <Modal open={isOpen} onClose={onClose}>
    <Box sx={modalStyle}>
      <Typography sx={{
        fontWeight: 'bold',
    }} variant="h6" gutterBottom>Sign Up</Typography>
      <TextField label="Email" variant="outlined" fullWidth margin="normal" />
      <TextField label="Password" variant="outlined" fullWidth margin="normal" type="password" />
      <Button sx={{
          backgroundColor: 'purple',
          fontWeight: 'bold',
        }} variant="contained" color="primary" fullWidth onClick={onClose}>Sign Up</Button>
    </Box>
  </Modal>
);

const LogOutModal = ({ isOpen, onClose }) => (
  <Modal open={isOpen} onClose={onClose}>
    <Box sx={modalStyle}>
      <Typography sx={{
        fontWeight: 'bold',
    }} variant="h6" gutterBottom>Log Out</Typography>
      <Typography variant="body1" gutterBottom>Are you sure you want to log out?</Typography>
      <Button sx={{
          backgroundColor: 'purple',
          fontWeight: 'bold',
        }} variant="contained" color="primary" onClick={onClose}>Log Out</Button>
    </Box>
  </Modal>
);


const NavigationBar = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPricingOpen, setPricingOpen] = useState(false);
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const [isLogOutOpen, setLogOutOpen] = useState(false);

  const buttons = [
    { id: 1, imgSrc: '/assets/Redesign/buttons/Button1.png', text: 'Ask A Question' },
    { id: 2, imgSrc: '/assets/Redesign/buttons/Button2.png', text: 'Daily Message' },
    { id: 3, imgSrc: '/assets/Redesign/buttons/Button3.png', text: 'Birth Chart' },
  ];

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  return (
    <AppBar position="sticky" color="default" sx={{ boxShadow: 'none', backgroundColor: 'white' }}>
      <Toolbar>
        <img onClick={() => navigate('/')} src={elvenLogo} alt="Elven AI" style={{ height: '40px', marginRight: '20px', cursor: 'pointer', // Change cursor to pointer to indicate it's clickable
    transition: 'transform 0.3s ease-in-out', // Smooth transition for hover effect
    '&:hover': {
      transform: 'scale(1.05)', // Slightly increase size on hover
    } }} />
        <Typography  variant="h6" color="inherit"  sx={{ flexGrow: 1, fontFamily: 'Fairies', cursor: 'pointer', // Change cursor to pointer
    transition: 'color 0.3s ease-in-out', // Smooth color transition
    '&:hover': {
      color: '#007bff', // Change color on hover
    } }}>
          Elven AI
        </Typography>
        <Typography
          edge="end"
          sx={{
            marginRight: '10px', // Adjust margin as needed
            fontWeight: 'bold',
          }}
          >Credits: 
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          sx={{
            transition: 'background-color 0.3s ease-in-out', // Smooth transition for background color
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)', // Change background on hover
            }
          }}
          onClick={menuOpen ? handleMenuClose : handleMenuClick}
        >
          {menuOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{
            '& .MuiPaper-root': { // Target the menu's paper component
              backgroundColor: 'rgba(255, 255, 255, 0.85)', // Semi-transparent background
              backdropFilter: 'blur(10px)', // Blur effect for the background
              boxShadow: '0px 8px 16px rgba(0,0,0,0.15)', // Soft shadow for depth
              borderRadius: '10px', // Rounded corners
              padding: '8px', // Padding around menu items
              // Add more styles as needed
            },
            '& .MuiMenuItem-root': { // Target the individual menu items
              color: '#333', // Menu item text color
              fontFamily: 'Arial, sans-serif', // Custom font
              fontSize: '1rem', // Font size
              fontWeight: 'bold', // Font weight
              padding: '10px 20px', // Padding inside menu items
              borderRadius: '5px', // Rounded corners for menu items
              margin: '5px 0', // Margin between menu items
              '&:hover': {
                backgroundColor: '#f0f0f0', // Change background on hover
                color: '#007bff', // Change text color on hover
                transition: 'all 0.3s ease-in-out', // Smooth transition for hover effects
              },
              // Add more styles as needed
            }
          }}
        >
          <MenuItem onClick={() => { handleMenuClose(); setPricingOpen(true); }}>Pricing</MenuItem>
<MenuItem onClick={() => { handleMenuClose(); setLoginOpen(true); }}>Log In</MenuItem>
<MenuItem onClick={() => { handleMenuClose(); setSignUpOpen(true); }}>Sign Up</MenuItem>
<MenuItem onClick={() => { handleMenuClose(); setLogOutOpen(true); }}>Log Out</MenuItem>

        </Menu>
      </Toolbar>
      <PricingModal 
        isPricingOpen={isPricingOpen} 
        setPricingOpen={setPricingOpen} 
        buttons={buttons}
      />
      <LoginModal isOpen={isLoginOpen} onClose={() => setLoginOpen(false)} />
      <SignUpModal isOpen={isSignUpOpen} onClose={() => setSignUpOpen(false)} />
      <LogOutModal isOpen={isLogOutOpen} onClose={() => setLogOutOpen(false)} />
    </AppBar>
    
  );
};

export default NavigationBar;
